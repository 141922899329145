import React from "react"
import { css } from "@emotion/core"

interface Props {
  children: JSX.Element | JSX.Element[]
}

const Quote = ({ children }: Props) => {
  return (
    <div
      css={css`
        margin-top: 20px;
        border-left: 5px solid #d9d9d9;
        padding-left: 10px;
        color: #898989;
      `}
    >
      {children}
    </div>
  )
}

export default Quote
