import { ColorResult, RGBColor } from "react-color"

export const getBestContrast = (color: ColorResult) => {
  const { r, g, b } = color.rgb
  const brightness = (299 * r + 587 * g + 114 * b) / 1000
  const whiteBrightness = (299 * 255 + 587 * 255 + 114 * 255) / 1000
  const blackBrightness = 0

  if (whiteBrightness - brightness > brightness - blackBrightness)
    return "#ffffff"
  return "#000000"
}

export const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

const parseRGB = (rgb: RGBColor) => {
  let r = 0
  let g = 0
  let b = 0

  if (rgb.r.toString() !== "NaN") r = rgb.r
  if (rgb.g.toString() !== "NaN") g = rgb.g
  if (rgb.b.toString() !== "NaN") b = rgb.b

  return {
    r,
    g,
    b,
  }
}

export const rgbToHex = (rgb: RGBColor) => {
  const { r, g, b } = parseRGB(rgb)
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}
