import React, { useState, useContext } from "react"
import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import { css } from "@emotion/core"
import { Button, notification, message } from "antd"
import { ColorResult } from "react-color"
import NewProjectPickTitle from "../../../components/new-project/NewProjectPickTitle"
import NewProjectSetBanner from "../../../components/new-project/NewProjectSetBanner"
import NewProjectPickType from "../../../components/new-project/NewProjectPickType"
import { Project, State } from "../../../context/State"
import { v4 } from "uuid"
import { Actions } from "../../../context/reducer"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../../context/GlobalContextProvider"
import { navigate } from "gatsby"

interface Props {}

const defaultColor: ColorResult = {
  hex: "#0693E3",
  rgb: {
    r: 6,
    g: 147,
    b: 227,
  },
  hsl: {
    h: 0,
    s: 0,
    l: 0,
  },
}

type NewProjectStage = "title" | "banner" | "project-type"

const NewProjectPage = (props: Props) => {
  const [stage, setStage] = useState<NewProjectStage>("title")
  const [loading, setLoading] = useState(false)

  const dispatch: any = useContext(GlobalDispatchContext)
  const state: State = useContext<any>(GlobalStateContext)

  const [name, setName] = useState("")
  const [image, setImage] = useState<any>()
  const [imageBlob, setImageBlob] = useState<any>()
  const [color, setColor] = useState<ColorResult>(defaultColor)

  const handleNextStage = () => {
    switch (stage) {
      case "title":
        if (name.length < 3 || name.length > 50) {
          notification["error"]({
            message:
              "Your project name must be between 3 and 50 characters long.",
            description: "",
          })
          return
        }
        setStage("banner")
        return
      case "banner":
        setStage("project-type")
        return
      case "project-type":
        createProject()
        return
    }
  }

  const createProject = async () => {
    if (state.user) {
      setLoading(true)
      console.log("Sending dispatch")
      const project: Project = {
        key: v4(),
        name,
        creatorUid: state.user.uid,
        users: [state.user.uid],
        backgroundColor: color.hex,
      }

      await dispatch({
        type: Actions.AddProject,
        project,
        image: imageBlob,
      })
      message.success("Success! Your new project was created.")
      setLoading(false)
      navigate("/dashboard")
    } else {
      message.error("Sorry, something went wrong. Try again in a moment.")
    }
  }

  return (
    <>
      <SEO title="New Project" />
      <Layout hideProjectHeader className="bg-white">
        <div
          css={css`
            max-width: 600px;
            margin: 0 auto;
            padding: 60px 20px 0px 20px;
            background: #ffffff;
          `}
        >
          <h1
            css={css`
              font-size: 32px;
              text-align: center;
              margin-bottom: 20px;
            `}
          >
            New Project
          </h1>
          {stage === "title" ? (
            <NewProjectPickTitle setName={setName} />
          ) : stage === "banner" ? (
            <NewProjectSetBanner
              name={name}
              image={image}
              setImage={setImage}
              blob={imageBlob}
              setBlob={setImageBlob}
              color={color}
              setColor={setColor}
            />
          ) : stage === "project-type" ? (
            <NewProjectPickType />
          ) : (
            <></>
          )}

          <div
            css={css`
              text-align: center;
              margin-bottom: 40px;
            `}
          >
            <Button
              onClick={handleNextStage}
              type="primary"
              size="large"
              loading={loading}
            >
              {stage === "project-type" ? "Create Project" : "Next Step"}
            </Button>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default NewProjectPage
