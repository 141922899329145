import React from "react"
import { Input } from "antd"
import FormItem from "../form/FormItem"
import { css } from "@emotion/core"

interface Props {
  setName: (name: string) => void
}

const NewProjectPickTitle = ({ setName }: Props) => {
  return (
    <div>
      <FormItem
        label="Project Name"
        description="Pick something short, sweet, and to the point."
        required
      >
        <Input
          placeholder="Grant Proposals, Board Elections, etc."
          onChange={e => setName(e.target.value)}
          size="large"
          css={css`
            width: 600px;
          `}
        />
      </FormItem>
    </div>
  )
}

export default NewProjectPickTitle
