import React from "react"
import ProjectTemplate from "./ProjectTemplate"
import Quote from "../../quote/Quote"

interface Props {}

const ProjectTemplateRoll = (props: Props) => {
  return (
    <div>
      <ProjectTemplate
        title="NIH Grant Proposals"
        description="Fully compliant with the NIH grant proposal scoring guidelines. Allow reviewers to vote only on the proposals assigned to them."
        selected
      />
      <Quote>
        <p>
          These are all the templates we have available right now, but we are
          always adding more. If you have a template idea in mind,{" "}
          <a>let us know!</a> We’d be happy to work with you in making your
          Primavote experience as seamless as possible.
        </p>
      </Quote>
    </div>
  )
}

export default ProjectTemplateRoll
