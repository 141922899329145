import React from "react"
import FormItem from "../form/FormItem"
import RadioCards from "../form/radio/RadioCards"
import ProjectTemplateRoll from "../form/template/ProjectTemplateRoll"

interface Props {}

const NewProjectPickType = (props: Props) => {
  return (
    <div>
      <FormItem
        label="How would you like to set up your project?"
        labelCentered
        required
      >
        <RadioCards
          options={[
            {
              title: "With a Template",
              description:
                "Pick from our range of templates to set up your project easily. You can customize the template you pick to suit your needs.",
              disabled: false,
            },
            {
              title: "From Scratch",
              description:
                "Got something else in mind? Build your project from the ground up.",
              disabled: true,
            },
          ]}
        />
      </FormItem>
      <FormItem label="Choose a Template" labelCentered required>
        <ProjectTemplateRoll />
      </FormItem>
    </div>
  )
}

export default NewProjectPickType
