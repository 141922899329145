import React from "react"
import { Input } from "antd"
import { css } from "@emotion/core"

interface Props {
  value: string
  prefix: string
  length: number
  onChange: (input: string) => void
}

const PillButtonInput = ({ value, prefix, length, onChange }: Props) => {
  const handleChange = (input: string) => {
    if (input.length > length) return
    onChange(input)
  }

  const parseValue = (text: string) => {
    if (text === "NaN") return ""
    else return text
  }

  return (
    <div>
      <Input
        value={parseValue(value)}
        prefix={<b>{prefix}</b>}
        onChange={e => handleChange(e.target.value)}
      />
    </div>
  )
}

export default PillButtonInput
