import React, { useState } from "react"
import { Input, Button, Switch } from "antd"
import FormItem from "../form/FormItem"
import { css } from "@emotion/core"
import { ColorResult, CirclePicker, RGBColor } from "react-color"
import { getBestContrast, hexToRgb, rgbToHex } from "../../helper/colorHelper"
import ImageUpload from "../ImageUpload"
import HexadecimalInput from "../form/color/HexadecimalInput"
import RGBInput from "../form/color/RGBInput"

interface Props {
  name: string
  image: any
  setImage: (image: any) => void
  blob: any
  setBlob: any
  color: ColorResult
  setColor: (color: ColorResult) => void
}

const NewProjectSetBanner = ({
  name,
  image,
  setImage,
  blob,
  setBlob,
  color,
  setColor,
}: Props) => {
  const [showColorOptions, setShowColorOptions] = useState(false)

  const handleHex = (hex: string) => {
    const rgb = hexToRgb(hex)
    if (!rgb) return
    setColor({
      hex,
      rgb,
      hsl: { h: 0, s: 0, l: 0 },
    })
  }

  const handleRGB = (rgb: RGBColor) => {
    const hex = rgbToHex(rgb)
    if (!hex) return

    setColor({
      hex,
      rgb,
      hsl: { h: 0, s: 0, l: 0 },
    })
  }

  return (
    <div>
      <FormItem
        label="Banner Preview"
        description="This banner appears at the top of each vote you create, so make sure it looks good!"
      >
        <div
          style={{ background: color.hex }}
          className="new-project__banner-preview"
        >
          {image ? <img src={image} alt={"Project image"} /> : <></>}
          <h2 style={{ color: getBestContrast(color) }}>{name}</h2>
        </div>
      </FormItem>

      <FormItem
        label="Banner Logo"
        description="This will appear on the project banner that voters see when voting."
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: start;
          `}
        >
          <ImageUpload
            imageUrl={image}
            setImageUrl={setImage}
            setImageBlob={setBlob}
          />
          <p
            css={css`
              margin-bottom: 0px;
              margin-left: 10px;
              color: #898989;
            `}
          >
            Accepted Types: PNG, JPG, SVG
          </p>
        </div>
      </FormItem>

      <div
        css={css`
          display: flex;
          justify-content: start;

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
        `}
      >
        <div
          css={css`
            flex: 1;
          `}
        >
          <FormItem label="Banner Color">
            <CirclePicker
              color={color.hex}
              onChange={color => setColor(color)}
            />
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <Switch onChange={e => setShowColorOptions(e)} />
              <span
                css={css`
                  margin-left: 5px;
                `}
              >
                Show advanced color options
              </span>
            </div>
          </FormItem>
        </div>
        <div
          css={css`
            flex: 1;
            display: ${showColorOptions ? "block" : "none"};
          `}
        >
          <FormItem label="Hexadecimal" margin={15} labelMargin={"0.25em"}>
            <HexadecimalInput hex={color.hex} setHex={handleHex} />
          </FormItem>
          <FormItem label="RGB" labelMargin={"0.25em"}>
            <RGBInput rgb={color.rgb} setRGB={handleRGB} />
          </FormItem>
        </div>
      </div>
    </div>
  )
}

export default NewProjectSetBanner
