import React from "react"
import { css, jsx } from "@emotion/core"

interface Props {
  centered?: boolean
  required?: boolean
  text: string
  marginBottom?: string
}

const FormLabel = ({ centered, required, text, marginBottom }: Props) => {
  return (
    <h1
      css={css`
        text-align: ${centered ? "center" : "left"};
        font-size: 18px;
        color: #2c2c2c;
        background: #ffffff;
        margin-bottom: ${marginBottom ? marginBottom : "0.5em"};
        ::before {
          content: "* ";
          color: #d65d5d;
          margin-left: -12px;
          display: ${required ? "inline" : "none"};
        }
      `}
    >
      {text}
    </h1>
  )
}

export default FormLabel
