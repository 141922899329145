import React, { useState } from "react"
import RadioCard from "./RadioCard"
import "./RadioCards.scss"

interface Props {
  options: {
    title: string
    description: string
    disabled: boolean
  }[]
}

const RadioCards = ({ options }: Props) => {
  const [indexSelected, setIndexSelected] = useState(0)

  console.log("Rendered")

  let index = -1
  return (
    <div className="radio-cards">
      {options.map(option => {
        index += 1
        return (
          <RadioCard
            key={index}
            index={index}
            title={option.title}
            description={option.description}
            disabled={option.disabled}
            onClick={index => setIndexSelected(index)}
            selected={index === indexSelected}
          />
        )
      })}
    </div>
  )
}

export default RadioCards
