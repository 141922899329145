import React from "react"
import FormLabel from "./FormLabel"
import { css } from "@emotion/core"

interface Props {
  children: JSX.Element | JSX.Element[]
  label: string
  labelCentered?: boolean
  description?: string
  required?: boolean
  margin?: number
  labelMargin?: string
}

const FormItem = ({
  children,
  label,
  labelCentered,
  description,
  required,
  margin,
  labelMargin,
}: Props) => {
  const marginBottom = margin ? `${margin}px` : "40px"

  return (
    <div
      css={css`
        margin-bottom: ${marginBottom};
      `}
    >
      <FormLabel
        text={label}
        centered={labelCentered}
        required={required}
        marginBottom={labelMargin}
      />
      {children}
      <p
        css={css`
          color: #898989;
          margin-top: 5px;
        `}
      >
        {description}
      </p>
    </div>
  )
}

export default FormItem
