import React from "react"
import FormLabel from "../FormLabel"
import PillButtonInput from "../inputs/PillButtonInput"
import { css } from "@emotion/core"
import { ColorResult, RGBColor } from "react-color"

interface Props {
  rgb: RGBColor
  setRGB: (rgb: RGBColor) => void
}

const RGBInput = ({ rgb, setRGB }: Props) => {
  const parseInput = (input: string) => {
    if (input === "") return NaN
    const num = parseInt(input)
    if (typeof num !== "number") return null
    if (num > 255) return null
    return num
  }

  const handleR = (input: number | string) => {
    const r = parseInput(input as string)
    if (r === null) return
    setRGB({ r, g: rgb.g, b: rgb.b })
  }
  const handleG = (input: number | string) => {
    const g = parseInput(input as string)
    if (g === null) return
    setRGB({ r: rgb.r, g: g, b: rgb.b })
  }
  const handleB = (input: number | string) => {
    const b = parseInput(input as string)
    if (b === null) return
    setRGB({ r: rgb.r, g: rgb.g, b })
  }

  return (
    <div
      css={css`
        display: "flex";
        div {
          flex: 1;
          width: 70px;
          display: inline-block;
          margin-left: 5px;
        }
        div:first-of-type {
          margin-left: 0px;
        }
      `}
    >
      <PillButtonInput
        value={rgb.r.toString()}
        prefix="R "
        length={3}
        onChange={r => handleR(r)}
      />
      <PillButtonInput
        value={rgb.g.toString()}
        prefix="G "
        length={3}
        onChange={g => handleG(g)}
      />
      <PillButtonInput
        value={rgb.b.toString()}
        prefix="B "
        length={3}
        onChange={b => handleB(b)}
      />
    </div>
  )
}

export default RGBInput
