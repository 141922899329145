import React, { useState, useEffect } from "react"
import PillButtonInput from "../inputs/PillButtonInput"
import { css } from "@emotion/core"

interface Props {
  hex: string
  setHex: (hex: string) => void
}

const HexadecimalInput = ({ hex, setHex }: Props) => {
  const [value, setValue] = useState(hex.substring(1, hex.length))

  const handleChange = (input: string) => {
    setValue(input)
    if (input.length === 6 || input.length === 3) {
      setHex(`#${input}`)
    }
  }

  useEffect(() => {
    setValue(hex.substring(1, hex.length))
  }, [hex])

  return (
    <div
      css={css`
        width: 100px;
      `}
    >
      <PillButtonInput
        value={value}
        prefix="#"
        length={6}
        onChange={input => handleChange(input)}
      />
    </div>
  )
}

export default HexadecimalInput
