import React from "react"
import "./RadioCard.scss"
import { CheckOutlined } from "@ant-design/icons"

interface Props {
  title: string
  description: string
  disabled: boolean
  index: number
  onClick: (index: number) => void
  selected?: boolean
}

const RadioCard = (props: Props) => {
  const { onClick, selected, title, description, disabled, index } = props

  const classNameType = selected ? "radio-card--selected" : ""
  const classDisabledType = disabled ? "radio-card--disabled" : ""

  return (
    <div
      className={`radio-card ${classNameType} ${classDisabledType}`}
      onClick={() => (disabled ? {} : onClick(index))}
    >
      <h3 className="radio-card__title">{title}</h3>
      <p className="radio-card__description">{description}</p>
      <CheckOutlined className="radio-card__check" />
    </div>
  )
}

export default RadioCard
