import React from "react"
import { css } from "@emotion/core"
import { CheckOutlined } from "@ant-design/icons"

interface Props {
  title: string
  description: string
  selected: boolean
}

const ProjectTemplate = ({ title, description, selected }: Props) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        :hover {
          border-left: 3px solid #1890ff;
          padding-left: 10px;
        }

        transition: all 0.1s;
      `}
    >
      <div
        css={css`
          flex: 1;
        `}
      >
        <h1
          css={css`
            font-size: 18px;
            margin-bottom: 0px;
          `}
        >
          {title}
        </h1>
        <p
          css={css`
            color: #898989;
            margin-bottom: 0px;
          `}
        >
          {description}
        </p>
      </div>
      <CheckOutlined
        css={css`
          margin-left: 30px;
          background: #1890ff;
          width: 24px;
          height: 24px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        `}
      />
    </div>
  )
}

export default ProjectTemplate
